import{getSettings}from'../settings';const ACCOUNT_IMPORT='ACCOUNT_IMPORT';const ACCOUNTS_IMPORT='ACCOUNTS_IMPORT';const STATUS_IMPORT='STATUS_IMPORT';const STATUSES_IMPORT='STATUSES_IMPORT';const POLLS_IMPORT='POLLS_IMPORT';const ACCOUNT_FETCH_FAIL_FOR_USERNAME_LOOKUP='ACCOUNT_FETCH_FAIL_FOR_USERNAME_LOOKUP';export function importAccount(account){return{type:ACCOUNT_IMPORT,account};}export function importAccounts(accounts){return{type:ACCOUNTS_IMPORT,accounts};}export function importStatus(status,idempotencyKey){return(dispatch,getState)=>{const expandSpoilers=getSettings(getState()).get('expandSpoilers');return dispatch({type:STATUS_IMPORT,status,idempotencyKey,expandSpoilers});};}export function importStatuses(statuses){return(dispatch,getState)=>{const expandSpoilers=getSettings(getState()).get('expandSpoilers');return dispatch({type:STATUSES_IMPORT,statuses,expandSpoilers});};}export function importPolls(polls){return{type:POLLS_IMPORT,polls};}export function importFetchedAccount(account){return importFetchedAccounts([account]);}export function importFetchedAccounts(accounts){const normalAccounts=[];const processAccount=account=>{if(!account.id)return;normalAccounts.push(account);if(account.moved){processAccount(account.moved);}};accounts.forEach(processAccount);return importAccounts(normalAccounts);}export function importFetchedStatus(status,idempotencyKey){return dispatch=>{var _status$reblog,_status$quote,_status$pleroma,_status$pleroma$quote,_status$reblog2,_status$reblog2$quote,_status$reblog3,_status$reblog3$plero,_status$reblog3$plero2,_status$poll;// Skip broken statuses
if(isBroken(status))return;if((_status$reblog=status.reblog)!==null&&_status$reblog!==void 0&&_status$reblog.id){dispatch(importFetchedStatus(status.reblog));}// Fedibird quotes
if((_status$quote=status.quote)!==null&&_status$quote!==void 0&&_status$quote.id){dispatch(importFetchedStatus(status.quote));}// Pleroma quotes
if((_status$pleroma=status.pleroma)!==null&&_status$pleroma!==void 0&&(_status$pleroma$quote=_status$pleroma.quote)!==null&&_status$pleroma$quote!==void 0&&_status$pleroma$quote.id){dispatch(importFetchedStatus(status.pleroma.quote));}// Fedibird quote from reblog
if((_status$reblog2=status.reblog)!==null&&_status$reblog2!==void 0&&(_status$reblog2$quote=_status$reblog2.quote)!==null&&_status$reblog2$quote!==void 0&&_status$reblog2$quote.id){dispatch(importFetchedStatus(status.reblog.quote));}// Pleroma quote from reblog
if((_status$reblog3=status.reblog)!==null&&_status$reblog3!==void 0&&(_status$reblog3$plero=_status$reblog3.pleroma)!==null&&_status$reblog3$plero!==void 0&&(_status$reblog3$plero2=_status$reblog3$plero.quote)!==null&&_status$reblog3$plero2!==void 0&&_status$reblog3$plero2.id){dispatch(importFetchedStatus(status.reblog.pleroma.quote));}if((_status$poll=status.poll)!==null&&_status$poll!==void 0&&_status$poll.id){dispatch(importFetchedPoll(status.poll));}dispatch(importFetchedAccount(status.account));dispatch(importStatus(status,idempotencyKey));};}// Sometimes Pleroma can return an empty account,
// or a repost can appear of a deleted account. Skip these statuses.
const isBroken=status=>{try{// Skip empty accounts
// https://gitlab.com/soapbox-pub/soapbox-fe/-/issues/424
if(!status.account.id)return true;// Skip broken reposts
// https://gitlab.com/soapbox-pub/soapbox/-/issues/28
if(status.reblog&&!status.reblog.account.id)return true;return false;}catch(e){return true;}};export function importFetchedStatuses(statuses){return(dispatch,getState)=>{const accounts=[];const normalStatuses=[];const polls=[];function processStatus(status){var _status$reblog4,_status$quote2,_status$pleroma2,_status$pleroma2$quot,_status$poll2;// Skip broken statuses
if(isBroken(status))return;normalStatuses.push(status);accounts.push(status.account);if((_status$reblog4=status.reblog)!==null&&_status$reblog4!==void 0&&_status$reblog4.id){processStatus(status.reblog);}// Fedibird quotes
if((_status$quote2=status.quote)!==null&&_status$quote2!==void 0&&_status$quote2.id){processStatus(status.quote);}if((_status$pleroma2=status.pleroma)!==null&&_status$pleroma2!==void 0&&(_status$pleroma2$quot=_status$pleroma2.quote)!==null&&_status$pleroma2$quot!==void 0&&_status$pleroma2$quot.id){processStatus(status.pleroma.quote);}if((_status$poll2=status.poll)!==null&&_status$poll2!==void 0&&_status$poll2.id){polls.push(status.poll);}}statuses.forEach(processStatus);dispatch(importPolls(polls));dispatch(importFetchedAccounts(accounts));dispatch(importStatuses(normalStatuses));};}export function importFetchedPoll(poll){return dispatch=>{dispatch(importPolls([poll]));};}export function importErrorWhileFetchingAccountByUsername(username){return{type:ACCOUNT_FETCH_FAIL_FOR_USERNAME_LOOKUP,username};}export{ACCOUNT_IMPORT,ACCOUNTS_IMPORT,STATUS_IMPORT,STATUSES_IMPORT,POLLS_IMPORT,ACCOUNT_FETCH_FAIL_FOR_USERNAME_LOOKUP};