'use strict';// Convenience function to load polyfills and return a promise when it's done.
// If there are no polyfills, then this is just Promise.resolve() which means
// it will execute in the same tick of the event loop (i.e. near-instant).
import"core-js/modules/web.dom-collections.iterator.js";import"core-js/modules/es.array.includes.js";function importBasePolyfills(){return import(/* webpackChunkName: "base_polyfills" */'./base_polyfills');}function importExtraPolyfills(){return import(/* webpackChunkName: "extra_polyfills" */'./extra_polyfills');}function loadPolyfills(){const needsBasePolyfills=!(// @ts-ignore
Array.prototype.includes&&// @ts-ignore
HTMLCanvasElement.prototype.toBlob&&window.Intl&&// @ts-ignore
Number.isNaN&&// @ts-ignore
Object.assign&&// @ts-ignore
Object.values&&window.Symbol);// Latest version of Firefox and Safari do not have IntersectionObserver.
// Edge does not have requestIdleCallback and object-fit CSS property.
// This avoids shipping them all the polyfills.
const needsExtraPolyfills=!(window.IntersectionObserver&&window.IntersectionObserverEntry&&'isIntersecting'in IntersectionObserverEntry.prototype&&window.requestIdleCallback&&'object-fit'in new Image().style);return Promise.all([needsBasePolyfills&&importBasePolyfills(),needsExtraPolyfills&&importExtraPolyfills()]);}export default loadPolyfills;